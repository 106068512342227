.commonModal {
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 99999999999999;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 6px; }
  @media screen and (min-width: 360px) and (max-width: 700px) {
    .commonModal {
      overflow-y: scroll;
      width: 95%; } }
  .commonModal .commonModalTit {
    line-height: 70px;
    margin: 0 20px;
    position: relative;
    border-bottom: 1px solid #eee; }
    .commonModal .commonModalTit > h3 {
      font-size: 1.125em;
      display: inline-block; }
    .commonModal .commonModalTit > .fa-times {
      position: absolute;
      right: 5px;
      top: 22px;
      line-height: 36px;
      font-size: 1.375em;
      cursor: pointer;
      width: 40px;
      height: 40px;
      text-align: center; }
      .commonModal .commonModalTit > .fa-times:hover {
        width: 40px;
        height: 40px;
        background-color: #eee;
        border-radius: 50%; }
  .commonModal .commonModalText {
    padding: 20px; }
    .commonModal .commonModalText > h4 {
      font-size: 1.375em; }
    .commonModal .commonModalText > p {
      padding-top: 10px;
      font-size: 1.125em; }
  .commonModal .commonModalInputTextWrap {
    margin: 0 20px; }
    .commonModal .commonModalInputTextWrap .commonModalInput {
      border: 1px solid #ccc;
      border-radius: 6px;
      padding: 14px 14px;
      font-size: 1.125em;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 10px; }
    .commonModal .commonModalInputTextWrap .commonModalInputText {
      color: red;
      padding-bottom: 10px; }
  .commonModal .commonModalBtnWrap {
    padding: 0 20px 20px 20px;
    float: right; }
    .commonModal .commonModalBtnWrap .commonModalBtnCancel {
      margin-left: 10px; }

.commonModal_layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; }
