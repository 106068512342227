.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sidebar-content {

  position: fixed;
}

ul,ol,li{
  list-style: none;
}

.rdx__popper{
  z-index: 10;
}
#sidebar {
  max-width: 200px;
  min-width: 200px;
}
@media (min-width: 1px) and (max-width: 991.98px){
  .sidebar {
    /*margin-left: -200px;*/
  }

}
.text-end{
  text-align: end;
}
.content {
  width: 100%;
}

#wrapper .card .table td, #wrapper .card .table th {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

#wrapper #tblPaymentRequest td .form-control {
  padding: .375rem .45rem;
}
#wrapper #tblPaymentRequest td  {
  padding: 11px;

}
#wrapper #tblPaymentRequest th  {
   padding: 11px;
  font-weight: 700;

 }
#wrapper #paymentRequestList td .form-control {
  padding: .375rem .45rem;
}
#wrapper #paymentRequestList td  {
  padding: 11px;

}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
