.custom-nav-tabs {
  border: none;
  border-bottom: 2px solid #ccc;
  margin-bottom: 10px; }
  .custom-nav-tabs > .custom-nav-item > .custom-nav-link {
    border: none;
    margin-bottom: unset; }
  .custom-nav-tabs > .custom-nav-item > .active {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    color: #222222 !important;
    border: none !important; }

.custom-card {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #cccccc !important; }
  .custom-card:nth-last-of-type(1) {
    border-bottom: none !important; }

.custom-card-header {
  border-radius: 0 !important;
  border: none !important;
  background: #ffffff; }

.custom-btn-link {
  color: #212529 !important; }

.custom-questions {
  background: #fafafa; }
