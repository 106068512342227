#customTabHeader {
  border-bottom: 1px solid #aaaaaa !important;
  margin-top: 30px; }
  #customTabHeader #customTabMenu {
    background: none; }
    #customTabHeader #customTabMenu > a {
      background: none; }
    #customTabHeader #customTabMenu > .active {
      font-size: 1.2em;
      color: #0a0c0d;
      font-weight: 900; }
